export const MASKS = {
  minHrMask: [/[0-9]/, /[0-9]/, ":", /[0-6]/, /[0-9]/],
  minHrAMPMMask: [/[0-1]/, /\d/, ":", /[0-6]/, /\d/, " ", /[a,p,A,P]/, /[m,M]/],
  phoneUSMask: [
    "(",
    /[2-9]/,
    /[0-9]/,
    /[0-9]/,
    ")",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ],
};

export const SUFFIX = [
  {
    id: "Jr",
    name: "Jr",
  },
  {
    id: "II",
    name: "II",
  },
  {
    id: "Sr",
    name: "Sr",
  },
  {
    id: "III",
    name: "II",
  },
  {
    id: "IV",
    name: "IV",
  },
  {
    id: "V",
    name: "V",
  },
];

export const GPS_SOURCE = [
  {
    id: "MOBILE_DEVICE",
    name: "MOBILE DEVICE",
  },
  {
    id: "VBUS_DEVICE",
    name: "VBUS Device",
  },
];

export const THRESHOLD_SPEED = [
  {
    id: "5",
    name: "5 Mile/Hr",
  },
];

export const THEME = [
  {
    id: "theme-tangaroa",
    name: "Tangaroa",
  },
];

export const UPDATE_FREQUENCY = [
  {
    id: "60000",
    name: "1 Minute",
  },
  {
    id: "300000",
    name: "5 Minutes",
  },
  {
    id: "900000",
    name: "15 Minutes",
  },
];

export const ASSET_TYPES = [
  {
    id: "VEHICLE",
    name: "Vehicle",
  },
  {
    id: "TRAILER",
    name: "Trailer",
  },
];

export const ALL_STATUS = {
  REMARK: "remark",
  ONDUTY_ND: "onDutyND",
  DRIVING: "driving",
  SLEEPER: "sleeper",
  OFFDUTY: "offDuty",
  BREAK: "break",
};

export const STATUS_TYPES = [
  {
    id: "remark",
    name: "Remark",
  },
  {
    id: "onDutyND",
    name: "OnDutyND",
  },
  {
    id: "driving",
    name: "Driving",
  },
  {
    id: "sleeper",
    name: "Sleeper",
  },
  {
    id: "offDuty",
    name: "OffDuty",
  },
  {
    id: "break",
    name: "Break",
  },
];

export const STATUS_CHANGE_TYPES = [
  {
    id: "onDutyND",
    name: "OnDutyND",
  },
  {
    id: "driving",
    name: "Driving",
  },
  {
    id: "sleeper",
    name: "Sleeper",
  },
  {
    id: "offDuty",
    name: "OffDuty",
  },
];

export const STATUS_OTHER_TYPES = [
  {
    id: "yardMoves",
    name: "Yard Moves",
  },
  {
    id: "personal",
    name: "Personal",
  },
];

export const USER_ROLES = [
  {
    id: "DRIVER",
    name: "Driver",
  },
  {
    id: "MANAGER",
    name: "Manager",
  },
];

export const ADMIN_USER_ROLES = [
  {
    id: "DRIVER",
    name: "Driver",
  },
  {
    id: "MANAGER",
    name: "Manager",
  },
  {
    id: "ADMIN",
    name: "Admin",
  },
];

export const CONNECTTION_TYPE = [
  {
    id: "bluetooth",
    name: "Bluetooth",
  },
  {
    id: "serial",
    name: "Serial",
  },
  {
    id: "wifi",
    name: "Wi Fi",
  },
];

export const DEVICE_TYPES = [
  {
    id: "PACIFIC_TRACK",
    name: "Pacific Track",
  },
  {
    id: "IOSIX",
    name: "IOSIX",
  },
];

export const DRIVER_CARGO = [
  {
    id: "PROPERTY",
    name: "PROPERTY",
  },
  {
    id: "PASSENGER",
    name: "PASSENGER",
  },
];

export const DRIVER_CYCLE_USA = [
  {
    id: "US70hr8days",
    name: "US70hr8days",
  },
  {
    id: "US60hr7days",
    name: "US60hr7days",
  },
  {
    id: "California80hr8days",
    name: "California80hr8days",
  },
];

export const DRIVER_CYCLE_CA = [
  {
    id: "Can70hr7daysSouth",
    name: "Can70hr7daysSouth",
  },
  {
    id: "Can120hr14daysSouth",
    name: "Can120hr14daysSouth",
  },
  {
    id: "Can80hr7daysNorth",
    name: "Can80hr7daysNorth",
  },
];

export const DRIVER_START_TIME = [
  {
    id: "12 AM",
    name: "12 AM",
  },
  // {
  //     id: "1 AM",
  //     name: "1 AM"
  // },
  // {
  //     id: "2 AM",
  //     name: "2 AM"
  // },
  // {
  //     id: "3 AM",
  //     name: "3 AM"
  // },
  // {
  //     id: "4 AM",
  //     name: "4 AM"
  // },
  // {
  //     id: "5 AM",
  //     name: "5 AM"
  // },
  // {
  //     id: "6 AM",
  //     name: "6 AM"
  // },
  // {
  //     id: "7 AM",
  //     name: "7 AM"
  // },
  // {
  //     id: "8 AM",
  //     name: "8 AM"
  // },
  // {
  //     id: "9 AM",
  //     name: "9 AM"
  // },
  // {
  //     id: "10 AM",
  //     name: "10 AM"
  // },
  // {
  //     id: "11 AM",
  //     name: "11 AM"
  // },
  // {
  //     id: "12 PM",
  //     name: "12 PM"
  // },
  // {
  //     id: "1 PM",
  //     name: "1 PM"
  // },
  // {
  //     id: "2 PM",
  //     name: "2 PM"
  // },
  // {
  //     id: "3 PM",
  //     name: "3 PM"
  // },
  // {
  //     id: "4 PM",
  //     name: "4 PM"
  // },
  // {
  //     id: "5 PM",
  //     name: "5 PM"
  // },
  // {
  //     id: "6 PM",
  //     name: "6 PM"
  // },
  // {
  //     id: "7 PM",
  //     name: "7 PM"
  // },
  // {
  //     id: "8 PM",
  //     name: "8 PM"
  // },
  // {
  //     id: "9 PM",
  //     name: "9 PM"
  // },
  // {
  //     id: "10 PM",
  //     name: "10 PM"
  // },
  // {
  //     id: "11 PM",
  //     name: "11 PM"
  // }
];

export const TIME_ZONES = [
  {
    id: "America/New_York",
    name: "Eastern Standard Time (EST)",
  },
  {
    id: "America/Chicago",
    name: "Central Standard Time (CST)",
  },
  {
    id: "America/Denver",
    name: "Mountain Standard Time (MST)",
  },
  {
    id: "America/Los_Angeles",
    name: "Pacific Standard Time (PST)",
  },
];

export const ALL_OPTIONS = [
  {
    name: "ON",
    id: "ON",
  },
  {
    name: "OFF",
    id: "OFF",
  },
];

export const ENABLE_DISABLE = [
  {
    name: "ENABLE",
    id: true,
  },
  {
    name: "DISABLE",
    id: false,
  },
];

export const API_STATUS = [
  {
    name: "ACTIVE",
    id: true,
  },
  {
    name: "INACTIVE",
    id: false,
  },
];

export const BUSS_TYPE = [
  {
    name: "DBA",
    id: "DBA",
  },
  {
    name: "INC",
    id: "INC",
  },
  {
    name: "LLC",
    id: "LLC",
  },
];

export const ALL_STATUS_OPTIONS = [
  {
    name: "Active",
    id: "ACTIVE",
  },
  {
    name: "Inactive",
    id: "IN_ACTIVE",
  },
  {
    name: "Delete",
    id: "DELETE",
  },
];

export const ALL_ACCOUNT_STATUS_OPTIONS = [
  {
    name: "Active",
    id: "ACTIVE",
  },
  {
    name: "On Hold",
    id: "ON_HOLD",
  },
  {
    name: "Inactive",
    id: "IN_ACTIVE",
  },
  {
    name: "Suspended",
    id: "SUSPENDED",
  },
  {
    name: "Delete",
    id: "DELETE",
  },
  {
    name: "Duplicate",
    id: "DUPLICATE",
  },
];

export const COUNTRIES = [
  {
    name: "United States",
    id: "US",
  },
  {
    name: "Canada",
    id: "CA",
  },
  {
    name: "Mexico",
    id: "MX",
  },
];

export const US_STATES = [
  {
    name: "Alabama",
    id: "AL",
    country: "US",
  },
  {
    name: "Alaska",
    id: "AK",
    country: "US",
  },
  {
    name: "American Samoa",
    id: "AS",
    country: "US",
  },
  {
    name: "Arizona",
    id: "AZ",
    country: "US",
  },
  {
    name: "Arkansas",
    id: "AR",
    country: "US",
  },
  {
    name: "California",
    id: "CA",
    country: "US",
  },
  {
    name: "Colorado",
    id: "CO",
    country: "US",
  },
  {
    name: "Connecticut",
    id: "CT",
    country: "US",
  },
  {
    name: "Delaware",
    id: "DE",
    country: "US",
  },
  {
    name: "District Of Columbia",
    id: "DC",
    country: "US",
  },
  {
    name: "Federated States Of Micronesia",
    id: "FM",
    country: "US",
  },
  {
    name: "Florida",
    id: "FL",
    country: "US",
  },
  {
    name: "Georgia",
    id: "GA",
    country: "US",
  },
  {
    name: "Guam",
    id: "GU",
    country: "US",
  },
  {
    name: "Hawaii",
    id: "HI",
    country: "US",
  },
  {
    name: "Idaho",
    id: "ID",
    country: "US",
  },
  {
    name: "Illinois",
    id: "IL",
    country: "US",
  },
  {
    name: "Indiana",
    id: "IN",
    country: "US",
  },
  {
    name: "Iowa",
    id: "IA",
    country: "US",
  },
  {
    name: "Kansas",
    id: "KS",
    country: "US",
  },
  {
    name: "Kentucky",
    id: "KY",
    country: "US",
  },
  {
    name: "Louisiana",
    id: "LA",
    country: "US",
  },
  {
    name: "Maine",
    id: "ME",
    country: "US",
  },
  {
    name: "Marshall Islands",
    id: "MH",
    country: "US",
  },
  {
    name: "Maryland",
    id: "MD",
    country: "US",
  },
  {
    name: "Massachusetts",
    id: "MA",
    country: "US",
  },
  {
    name: "Michigan",
    id: "MI",
    country: "US",
  },
  {
    name: "Minnesota",
    id: "MN",
    country: "US",
  },
  {
    name: "Mississippi",
    id: "MS",
    country: "US",
  },
  {
    name: "Missouri",
    id: "MO",
    country: "US",
  },
  {
    name: "Montana",
    id: "MT",
    country: "US",
  },
  {
    name: "Nebraska",
    id: "NE",
    country: "US",
  },
  {
    name: "Nevada",
    id: "NV",
    country: "US",
  },
  {
    name: "New Hampshire",
    id: "NH",
    country: "US",
  },
  {
    name: "New Jersey",
    id: "NJ",
    country: "US",
  },
  {
    name: "New Mexico",
    id: "NM",
    country: "US",
  },
  {
    name: "New York",
    id: "NY",
    country: "US",
  },
  {
    name: "North Carolina",
    id: "NC",
    country: "US",
  },
  {
    name: "North Dakota",
    id: "ND",
    country: "US",
  },
  {
    name: "Northern Mariana Islands",
    id: "MP",
    country: "US",
  },
  {
    name: "Ohio",
    id: "OH",
    country: "US",
  },
  {
    name: "Oklahoma",
    id: "OK",
    country: "US",
  },
  {
    name: "Oregon",
    id: "OR",
    country: "US",
  },
  {
    name: "Palau",
    id: "PW",
    country: "US",
  },
  {
    name: "Pennsylvania",
    id: "PA",
    country: "US",
  },
  {
    name: "Puerto Rico",
    id: "PR",
    country: "US",
  },
  {
    name: "Rhode Island",
    id: "RI",
    country: "US",
  },
  {
    name: "South Carolina",
    id: "SC",
    country: "US",
  },
  {
    name: "South Dakota",
    id: "SD",
    country: "US",
  },
  {
    name: "Tennessee",
    id: "TN",
    country: "US",
  },
  {
    name: "Texas",
    id: "TX",
    country: "US",
  },
  {
    name: "Utah",
    id: "UT",
    country: "US",
  },
  {
    name: "Vermont",
    id: "VT",
    country: "US",
  },
  {
    name: "Virgin Islands",
    id: "VI",
    country: "US",
  },
  {
    name: "Virginia",
    id: "VA",
    country: "US",
  },
  {
    name: "Washington",
    id: "WA",
    country: "US",
  },
  {
    name: "West Virginia",
    id: "WV",
    country: "US",
  },
  {
    name: "Wisconsin",
    id: "WI",
    country: "US",
  },
  {
    name: "Wyoming",
    id: "WY",
    country: "US",
  },
];

export const CA_STATES = [
  {
    id: "AB",
    name: "Alberta",
    country: "CA",
  },
  {
    id: "BC",
    name: "British Columbia",
    country: "CA",
  },
  {
    id: "MB",
    name: "Manitoba",
    country: "CA",
  },
  {
    id: "NB",
    name: "New Brunswick",
    country: "CA",
  },
  {
    id: "NL",
    name: "Newfoundland and Labrador",
    country: "CA",
    alt: ["Newfoundland", "Labrador"],
  },
  {
    id: "NS",
    name: "Nova Scotia",
    country: "CA",
  },
  {
    id: "NU",
    name: "Nunavut",
    country: "CA",
  },
  {
    id: "NT",
    name: "Northwest Territories",
    country: "CA",
  },
  {
    id: "ON",
    name: "Ontario",
    country: "CA",
  },
  {
    id: "PE",
    name: "Prince Edward Island",
    country: "CA",
  },
  {
    id: "QC",
    name: "Quebec",
    country: "CA",
  },
  {
    id: "SK",
    name: "Saskatchewan",
    country: "CA",
  },
  {
    id: "YT",
    name: "Yukon",
    country: "CA",
  },
];

export const MX_STATES = [
  { id: "AG", name: "Aguascalientes", country: "MX" },
  { id: "BC", name: "Baja California", country: "MX" },
  { id: "BS", name: "Baja California Sur", country: "MX" },
  { id: "CM", name: "Campeche", country: "MX" },
  { id: "CS", name: "Chiapas", country: "MX" },
  { id: "CH", name: "Chihuahua", country: "MX" },
  { id: "CO", name: "Coahuila", country: "MX" },
  { id: "CL", name: "Colima", country: "MX" },
  { id: "DG", name: "Durango", country: "MX" },
  { id: "GT", name: "Guanajuato", country: "MX" },
  { id: "GR", name: "Guerrero", country: "MX" },
  { id: "HG", name: "Hidalgo", country: "MX" },
  { id: "JA", name: "Jalisco", country: "MX" },
  { id: "EM", name: "Mexico State", country: "MX" },
  { id: "MI", name: "Michoacán", country: "MX" },
  { id: "MO", name: "Morelos", country: "MX" },
  { id: "NA", name: "Nayarit", country: "MX" },
  { id: "NL", name: "Nuevo León", country: "MX" },
  { id: "OA", name: "Oaxaca", country: "MX" },
  { id: "PU", name: "Puebla", country: "MX" },
  { id: "QT", name: "Querétaro", country: "MX" },
  { id: "QR", name: "Quintana Roo", country: "MX" },
  { id: "SL", name: "San Luis Potosí", country: "MX" },
  { id: "SI", name: "Sinaloa", country: "MX" },
  { id: "SO", name: "Sonora", country: "MX" },
  { id: "TB", name: "Tabasco", country: "MX" },
  { id: "TM", name: "Tamaulipas", country: "MX" },
  { id: "TL", name: "Tlaxcala", country: "MX" },
  { id: "VE", name: "Veracruz", country: "MX" },
  { id: "YU", name: "Yucatán", country: "MX" },
  { id: "ZA", name: "Zacatecas", country: "MX" },
  { id: "CD", name: "Mexico City", country: "MX" },
];

export const ALL_STATES = [...US_STATES, ...CA_STATES, ...MX_STATES];

export const EDIT_REASONS = [
  {
    id: "Rental Vehicle no ELD on-board",
    name: "Rental Vehicle no ELD on-board",
    es: "Vehículo de alquiler no ELD a bordo",
    pn: "Véhicule de location sans ELD à bord",
  },
  {
    id: "ELD Device Failure",
    name: "ELD Device Failure",
    es: "Fallo del dispositivo ELD",
    pn: "Panne de périphérique ELD",
  },
  {
    id: "Missing GPS Location",
    name: "Missing GPS Location",
    es: "Falta la ubicación del GPS",
    pn: "Localisation GPS manquante",
  },
  {
    id: "Incorrect Status Selected",
    name: "Incorrect Status Selected",
    es: "Estado incorrecto seleccionado",
    pn: "Statut incorrect sélectionné",
  },
  {
    id: "Incorrect Data Entered",
    name: "Incorrect Data Entered",
    es: "Datos incorrectos ingresados",
    pn: "Données incorrectes entrées",
  },
  {
    id: "Driver Not Logged On",
    name: "Driver Not Logged On",
    es: "Controlador no conectado",
    pn: "Pilote non connecté",
  },
  {
    id: "Engineers Road Test",
    name: "Engineers Road Test",
    es: "Ingenieros Prueba de carretera",
    pn: "Essai sur route des ingénieurs",
  },
  {
    id: "Other",
    name: "Other",
    es: "Otro",
    pn: "Autre",
  },
];

export const VIOLATIONS = {
  30: "30 Minute Break Required",
  11: "Driving beyond the 11 Hours of Drive limit",
  13: "Driving beyond the 13 Hours of Drive limit",
  14: "Driving beyond the 14 Hours of Shift limit",
  // '14d': "Driving beyond the 14 Hours of Drive limit",
  16: "Driving beyond the 16 Hours of Shift limit",
  "-14": "Potential violation",
  60: "Driving beyond the 60 Hours of Cycle Limit",
  70: "Driving beyond the 70 Hours of Cycle Limit",
  10: "Driving beyond the 10 Hours of Drive limit",
  15: "Driving beyond the 15 Hours of Shift limit",
  "70": "Driving beyond the 70 Hours of Cycle Limit",
  18: "Driving beyond the 18 Hours of Shift limit",
  80: "Driving beyond the 80 Hours of Cycle Limit",
  12: "Driving beyond the 12 Hours of Drive limit",
};
export const VIOLATIONS_Calforina = {
  30: "30 Minute Break Required",
  11: "Driving beyond the 11 Hours of Drive limit",
  13: "Driving beyond the 13 Hours of Drive limit",
  // 14: "Driving beyond the 14 Hours of Shift limit",
  14: "Driving beyond the 14 Hours of Drive limit",
  16: "Driving beyond the 16 Hours of Shift limit",
  "-14": "Potential violation",
  60: "Driving beyond the 60 Hours of Cycle Limit",
  70: "Driving beyond the 70 Hours of Cycle Limit",
  10: "Driving beyond the 10 Hours of Drive limit",
  15: "Driving beyond the 15 Hours of Shift limit",
  "70": "Driving beyond the 70 Hours of Cycle Limit",
  18: "Driving beyond the 18 Hours of Shift limit",
  80: "Driving beyond the 80 Hours of Cycle Limit",
  12: "Driving beyond the 12 Hours of Drive limit",
};

export const EXCEPTIONS = {
  1: "Adverse weather condition",
  2: "Once in a week",
  3: "Agriculture Exemption",
};

export const REGULATION = [
  {
    id: "ELD",
    name: "ELD",
  },
  {
    id: "LOGBOOK",
    name: "Log Book",
  },
];

export const REGULATION_ADMIN = [
  {
    id: "ELD",
    name: "ELD",
  },
  {
    id: "LOGBOOK",
    name: "Log Book",
  },
];

export const DRIVER_VIEW_OPTIONS = [
  {
    id: "SINGLE",
    name: "Single",
  },
  {
    id: "ALL",
    name: "All",
  },
];

export const NOTIFICATION_SETUP_TYPES = [
  {
    id: "OVERSPEED",
    name: "OVERSPEED",
  },
  {
    id: "HOS_VIOLATION",
    name: "HOS_VIOLATION",
  },
];

export const EQUIPMENT_FUEL = [
  {
    id: "DIESEL",
    name: "Diesel",
  },
  {
    id: "ELECTRIC",
    name: "Electric",
  },
  {
    id: "PETROL",
    name: "Petrol",
  },
  {
    id: "ALTERNATIVE FUELS",
    name: "Alternative fuels",
  },
];

export const EQUIPMENT_TYPE = [
  {
    id: "HEAVY_DUTY_VEHICLE",
    name: "Heavy Duty Vehicle",
  },
  {
    id: "LIGHT_DUTY_VEHICLE",
    name: "Light Duty Vehicle",
  },
];

export const SUPPORT_TYPES = [
  {
    id: "INBOUND",
    name: "In Bound",
  },
  {
    id: "OUTBOUND",
    name: "Out Bound",
  },
];

export const SUPPORT_CATEGORY = [
  {
    id: "SALE",
    name: "Sale",
  },
  {
    id: "SUPPORT",
    name: "Support",
  },
  {
    id: "INQUIRY",
    name: "Inquiry",
  },
  {
    id: "IFTA",
    name: "IFTA",
  },
  {
    id: "AUDIT",
    name: "Audit",
  },
];

export const SUPPORT_PRODUCT = [
  {
    id: "ELD",
    name: "Eld",
  },
  {
    id: "DASHCAMERA",
    name: "Dashcameras",
  },
  {
    id: "GPS",
    name: "GPS",
  },

  {
    id: "OSRIT",
    name: "Osrit",
  },
];

export const SUPPORT_CALL_STATUS = [
  {
    id: "OPEN",
    name: "Open",
  },
  {
    id: "IN_PROGRESS",
    name: "In Progress",
  },
  {
    id: "BLOCKED",
    name: "Blocked",
  },
  {
    id: "RESOLVED",
    name: "Resolved",
  },
];

export const SUPPORT_CALL_PRORIRY = [
  {
    id: "HIGH",
    name: "High",
  },
  {
    id: "MEDIUM",
    name: "Medium",
  },
  {
    id: "LOW",
    name: "Low",
  },
];

export const SUPPORT_CALL_BACK = [
  {
    id: "YES",
    name: "Yes",
  },
  {
    id: "NO",
    name: "No",
  },
];

export const SHIPPING_STATUS = [
  {
    id: "NEW",
    name: "New",
  },
  {
    id: "CREATED",
    name: "Created",
  },
  {
    id: "SHIPPED",
    name: "Shipped",
  },
  {
    id: "DELIVERED",
    name: "Delivered",
  },
];

export const SHIPPING_PROVIDER = [
  {
    id: "USPS",
    name: "USPS",
  },
  {
    id: "UPS",
    name: "UPS",
  },
  {
    id: "FEDEX",
    name: "FedEx",
  },
  {
    id: "PICKUP",
    name: "Pickup",
  },
];

export const TODO_STATUS = [
  {
    id: "NEW",
    name: "New",
  },
  {
    id: "IN_PROGRESS",
    name: "In progress",
  },
  {
    id: "COMPLETE",
    name: "Complete",
  },
];

export const TODO_PRORIRY = [
  {
    id: "HIGH",
    name: "High",
  },
  {
    id: "MEDIUM",
    name: "Medium",
  },
  {
    id: "LOW",
    name: "Low",
  },
];

export const QUEUE_PRORIRY = [
  {
    id: "HIGH",
    name: "High",
  },
  {
    id: "MEDIUM",
    name: "Medium",
  },
  {
    id: "LOW",
    name: "Low",
  },
];

export const QUEUE_STATUS = [
  {
    id: "NEW",
    name: "New",
  },
  {
    id: "IN_PROGRESS",
    name: "In progress",
  },
  {
    id: "COMPLETE",
    name: "Complete",
  },
  {
    id: "CALLBACK",
    name: "Callback",
  },
];

export const QUEUE_REASON = [
  {
    id: "ADD_DRIVER",
    name: "Add new driver",
  },
  {
    id: "ADD_VEHICLE",
    name: "Add new vehicle",
  },
  {
    id: "IFTA",
    name: "Help with ifta",
  },
  {
    id: "DOT",
    name: "DOT Inspection",
  },
  {
    id: "ROAD_SIDE",
    name: "Roadside inspection",
  },
  {
    id: "DUTY_STATUS",
    name: "Duty status",
  },
  {
    id: "LOG_IN",
    name: "Login issue",
  },
  {
    id: "ELD_CONNECT",
    name: "ELD connection issue",
  },
  {
    id: "DVIR",
    name: "Help with dvir",
  },
  {
    id: "Other",
    name: "Other",
  },
];

export const WAITLIST_STATUS = [
  {
    id: "NEW",
    name: "New",
  },
  {
    id: "IN_PROGRESS",
    name: "In progress",
  },
  {
    id: "COMPLETE",
    name: "Complete",
  },
];

export const WAITLIST_CALLER = [
  {
    id: "DRIVER",
    name: "Driver",
  },
  {
    id: "DISPACHER",
    name: "Dispacher",
  },
  {
    id: "OWNER",
    name: "Owner",
  },
];

export const BLOCK_SCOPES_SM_ADMIN = [];
export const BLOCK_SCOPES_EB_ADMIN = [];
export const BLOCK_SCOPES_VS_ADMIN = [];
export const BLOCK_SCOPES_BH_ADMIN = ["RS"];
export const BLOCK_SCOPES_MO_ADMIN = ["SP", "RS"];

export const LEAD_STATUS = [
  {
    id: "NEW",
    name: "New",
  },
  {
    id: "IN_PROGRESS",
    name: "In progress",
  },
  {
    id: "ON_HOLD",
    name: "On hold",
  },
  {
    id: "SALE",
    name: "Sale",
  },
];

export const LEAD_CHANNEL = [
  {
    id: "ONLINE",
    name: "Online",
  },
  {
    id: "REFERRAL",
    name: "Referral",
  },
  {
    id: "OTHER",
    name: "Other",
  },
];

export const TRUE_FALSE = [
  {
    name: "YES",
    id: true,
  },
  {
    name: "NO",
    id: false,
  },
];

export const TWO_FAC_TYPE = [
  {
    id: "EMAIL",
    name: "Email",
  },
  {
    id: "SMS",
    name: "Sms",
  },
];

export const MAINTENANCE_TYPE = [
  {
    id: "BRAKE",
    name: "Brake change",
  },
  {
    id: "RADIATOR",
    name: "Radiator",
  },
  {
    id: "TRUCK_LUBE",
    name: "Truck lube",
  },
  {
    id: "TRAILER_TUBE",
    name: "Trailer lube",
  },
  {
    id: "OIL_CHANGE",
    name: "Oil change",
  },
];

export const MAINTENANCE_STATUS = [
  {
    id: "NEW",
    name: "New",
  },
  {
    id: "PENDING",
    name: "Pending",
  },
  {
    id: "IN_PROGRESS",
    name: "In progress",
  },
  {
    id: "COMPLETE",
    name: "Complete",
  },
];

export const MAINTENANCE_DUE_BY = [
  {
    id: "MILEAGE",
    name: "Mileage",
  },
  // {
  //   id: "TIME",
  //   name: "Time",
  // },
];

export const MAINTENANCE_ASSET_TYPE = [
  {
    id: "VEHICLE",
    name: "Vehicle",
  },
  // {
  //   id: "TRAILER",
  //   name: "Trailer",
  // },
];

export const PLAN_OPTIONS = [
  { id: "monthly", name: "Monthly" },
  { id: "annually", name: "Annually" },
];

export const PLAN_NAME_OPTIONS = [
  { id: "STANDARD", name: "Standard" },
  { id: "PREMIUM", name: "Premium" },
  { id: "ENTERPRISE", name: "Enterprise" },
];
