import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
// import { signIn } from "../../actions/auth/authActions";
import { getTimeZone } from "../../actions/momentActions";
import { updateUser } from "../../actions/adminDashActions";
import { getResellers } from "../../actions/reseller/resellerActions";
import {  validateEdit } from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import Success from "../Success";
import CheckBox from "../../components/CheckBox";
import { LABELS as _LABELS } from "../../language";
import {
    ALL_STATUS_OPTIONS,
} from "../../constants";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 25,
    textAlign: "right",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddUserForm extends React.Component {
  state = {
    resellers: [],
  };

  componentDidMount() {
    this.getResellers();
  }

  getResellers = async () => {
    try {
      let resellers = await this.props.getResellers({});
      this.setState({ resellers });
    } catch (e) {}
  };

  handleSubmit = async (values) => {
    let response = await this.props.updateUser(values);
    if (response && response._id) {
      this.setState({
        successMessage: LABELS.user_is_updated_successfully,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = LABELS.response;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { constants } = this.props;
    const selected = this.props.selected || {};
    const resellers = this.state.resellers || [];
    const { errorMessage, successMessage, loading } = this.state;

    return (
      <div>
        <Formik
          initialValues={selected}
          validate={validateEdit}
          onSubmit={this.handleSubmit}
          enableReinitialize={true}
        >
          {({ values, handleSubmit, setFieldValue, errors }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <SelectBox
                    md={9}
                    name="resellerId"
                    label={LABELS.reseller}
                    items={resellers}
                    selValue="_id"
                  />
                  <InputBox md={3} name="phone" label={LABELS.phone} />
                  <InputBox md={3} name="firstName" label={LABELS.firstName} />
                  <InputBox md={3} name="lastName" label={LABELS.lastName} />
                  <InputBox md={3} name="email" label={LABELS.email} />
                  <InputBox md={3} name="password" label={LABELS.password} />
                  <SelectBox
                      md={3}
                      name="status"
                      label={'Status'}
                      items={constants.ALL_STATUS_OPTIONS}
                  />
                  <SelectBox
                    md={3}
                    name="twoFacType"
                    label={'Two-factorauth'}
                    items={constants.TWO_FAC_TYPE}
                  />
                  <InputBox md={3} name="emailmfa" label={'MFA Email'} />
                  <InputBox md={3} name="mobile" label={LABELS.mobile} />
                  <CheckBox
                    md={2}
                    name="isTwoFac"
                    label={"Two-factorauth"}
                    onChange={(e) => {
                      setFieldValue("isTwoFac", e.target.checked);
                    }}
                  />
                  <CheckBox
                    md={2}
                    name="adminAccess"
                    label={LABELS.superAdmin}
                  />
                  <CheckBox
                    md={2}
                    name="adminSupport"
                    label={LABELS.supportAdmin}
                  />
                </Grid>
                {successMessage && <Success message={successMessage} />}
                {errorMessage && <Error message={errorMessage} />}
                <Grid
                  item
                  md={12}
                  xs={12}
                  style={{
                    marginTop: 15,
                    textAlign: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    {loading ? LABELS.saving : LABELS.save}
                  </Button>{" "}
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser,
      getTimeZone,
      getResellers,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUserForm))
);
