// import _package from "../package.json";

const NAME_SMARTELDS = "Smartelds";
const NAME_UNITY = "Unity";
const NAME_EB = "Eldbooks";
const NAME_BH = "Bluehorse";
const NAME_VS = "Vulcansols";
const NAME_RE = "Radical";

const ANDROID_PACKAGE_SMARTELDS = "com.asrit.smartelds";
const IOS_PACKAGE_SMARTELDS = "smart-elds/id1578223541";

const ANDROID_PACKAGE_UNITY = "motion.com.eld";
const IOS_PACKAGE_UNITY = "motionseld/id6446702485";

const ANDROID_PACKAGE_EB = "com.eld.eldbooks";
const IOS_PACKAGE_EB = "eldbooks/id6476132106";

const ANDROID_PACKAGE_BH = "com.eld.bluehorse";
const IOS_PACKAGE_BH = "bluehorse-eld/id6479435773";

const ANDROID_PACKAGE_VS = "com.vulcansols.eld";
const IOS_PACKAGE_VS = "vulcansols/id6670468156";

const ANDROID_PACKAGE_RE = "com.radical.eld";
const IOS_PACKAGE_RE = "radical/123";

const appNames = {
  Smartelds: "SE",
  Unity: "ME",
  Eldbooks: "EB",
  Bluehorse: "BH",
  Vulcansols: "VS",
  Radical: "RE",
};

const isSmartelds = process.env.REACT_APP_CLIENT_APP === NAME_SMARTELDS;
const isUnity = process.env.REACT_APP_CLIENT_APP === NAME_UNITY;
const isEldBooks = process.env.REACT_APP_CLIENT_APP === NAME_EB;
const isBluehorse = process.env.REACT_APP_CLIENT_APP === NAME_BH;
const isVulcansols = process.env.REACT_APP_CLIENT_APP === NAME_VS;
const isRadical = process.env.REACT_APP_CLIENT_APP === NAME_RE;

const selApp = appNames[process.env.REACT_APP_CLIENT_APP];

let andriodPackage = ANDROID_PACKAGE_SMARTELDS;
let iosPackage = IOS_PACKAGE_SMARTELDS;

if (isUnity) {
  andriodPackage = ANDROID_PACKAGE_UNITY;
  iosPackage = IOS_PACKAGE_UNITY;
}
if (isEldBooks) {
  andriodPackage = ANDROID_PACKAGE_EB;
  iosPackage = IOS_PACKAGE_EB;
}
if (isBluehorse) {
  andriodPackage = ANDROID_PACKAGE_BH;
  iosPackage = IOS_PACKAGE_BH;
}

if (isVulcansols) {
  andriodPackage = ANDROID_PACKAGE_VS;
  iosPackage = IOS_PACKAGE_VS;
}

if (isRadical) {
  andriodPackage = ANDROID_PACKAGE_RE;
  iosPackage = IOS_PACKAGE_RE;
}

const andriodUrl = `https://play.google.com/store/apps/details?id=${andriodPackage}`;
const iosUrl = `https://apps.apple.com/us/app/${iosPackage}`;
const isQueueWait = isSmartelds || isEldBooks;
export {
  isSmartelds,
  isUnity,
  isEldBooks,
  isBluehorse,
  isVulcansols,
  isRadical,
  selApp,
  andriodUrl,
  iosUrl,
  isQueueWait,
};
